import styled from 'styled-components';
import { sizes } from '../../../../styles/variables';

export const PatientCardVisibleContent = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 30%;
  }

  button {
    right: 0;
    transition: ${(props) => props.theme.transition};
  }

  @media (min-width: ${sizes.mdMin}) and (hover: hover) {
    & > div:last-child {
      width: auto;
    }
  }

  @media (max-width: ${sizes.smMax}) or (hover: none) {
    & > div {
      width: 40%;
    }
  }
`;
