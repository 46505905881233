import styled, { css } from 'styled-components';

export const QuillWrapper = styled.div`
  width: 100%;

  .ql-snow {
    &.ql-container {
      ${(props) => props.inForm && css`
        border: none;
      `}

      .ql-editor {
        padding: 10px 7px;
        font-family: 'Muli';
        font-size: ${(props) => props.theme.font.size.medium};
        font-weight: ${(props) => props.theme.font.weight.regular};
        color: ${(props) => props.theme.colors.black};
        ${(props) => props.inForm && css`
          border-bottom: 1px solid ${props.theme.colors.gray400};
        `}
      }
    }
  }
`;
