import styled from 'styled-components';

export const ToolbarWrapper = styled.div`
  #toolbar {
    border: none;
  }

  .ql-snow.ql-toolbar .ql-formats button:hover {
    color: ${(props) => props.theme.colors.primary};

    .ql-stroke {
      stroke: ${(props) => props.theme.colors.primary};
    }
  }
`;
