import React from 'react';

import { ToolbarWrapper } from './styled/ToolbarWrapper';

export default () => (
  <ToolbarWrapper>
    <div id="toolbar">
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold" type="button" />
        <button className="ql-italic" aria-label="Italic" type="button" />
        <button className="ql-underline" aria-label="Underline" type="button" />
        <button className="ql-strike" aria-label="Strike" type="button" />
        <button className="ql-blockquote" aria-label="Blockquote" type="button" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" aria-label="Ordered List" type="button" />
        <button className="ql-list" value="bullet" aria-label="Unordered List" type="button" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" aria-label="Link" type="button" />
      </span>
    </div>
  </ToolbarWrapper>
);
