/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { isEmpty } from '../../../utils/ObjectUtils';
import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import Loading from '../../Common/Loading';

import { Margin } from '../../Common/styled/Margins';
import { Row, Column } from '../../Common/styled/Groups';
import { Error, Form, Label } from '../../Common/styled/Form';
import QuillEditor from '../../Quill/QuillEditor';
import { medicalSpecialtyList } from '../../../utils/medicalSpecialtyList';
import { PatientCardDialog } from '../../Common/PatientCard/PatientCard';

import { GET_PATIENTS_ON_NEW, GET_RECENT_MEDICAL_CASES } from '../../../graphql/queries';
import { CREATE_MEDICAL_CASE } from '../../../graphql/mutations';
import { TextFieldUI } from '../../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../../componentsUI/TextFieldSelect';
import { TextFieldSearch } from '../../../componentsUI/TextFieldSearch';
import { AlertUI } from '../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxHeight: 600,
      height: '85%',
      maxWidth: 800,
    },
    '& H4': {
      margin: 0,
    },
  },
  wrapperTitle: {
    display: 'flex',
    padding: '6px 24px',
    '& .MuiDialogTitle-root': {
      padding: 0,
      marginRight: 'auto',
    },
  },
  wrapperSearch: {
    padding: '0 24px',
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  closeButton: {
    padding: '6px',
  },
  wrapperStep1: {
    padding: '0 24px',
  },
  content: {
    margin: '8px 24px',
    padding: 0,
    paddingRight: 5,
  },
  search: {
    margin: '10px 0',
  },
}));

export const NewCaseDialog = ({ location, open, onClose, refetch }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState();
  const [step, setStep] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(location && location.state && location.state.patient ? location.state.patient.uuid : '');

  useEffect(() => {
    setStep(0);
    setSearch('');
    setSelectedPatient('');
  }, [open]);

  const [createCase, { loading, called }] = useMutation(
    CREATE_MEDICAL_CASE,
    {
      onCompleted: () => {
        refetch();
        onClose();
      },
      refetchQueries: [{
        query: GET_RECENT_MEDICAL_CASES,
        variables: {
          hospitalUuid,
          first: 5,
          orderBy: {
            field: 'CREATED_AT',
            direction: 'DESC',
          },
        },
      }],
      awaitRefetchQueries: true,
    },
  );

  // Get patients in hospital
  const { data: patientsData } = useQuery(
    GET_PATIENTS_ON_NEW,
    {
      variables: {
        hospitalUuid,
        orderBy: { field: 'SURNAME', direction: 'ASC' },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const filteredPatients = isEmpty(patientsData) ? [] : patientsData.patients.edges.filter(
    (edge) => edge.node.name.toLowerCase().includes(search.trim().toLowerCase()) || edge.node.surname.toLowerCase().includes(search.trim().toLowerCase()),
  );

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('required.field')),
    description: Yup.string().required(t('required.field')),
    specialty: Yup.string().required(t('required.field')),
  });

  const initialValues = {
    title: '',
    description: '',
    specialty: medicalSpecialtyList[0].value,
    active: 'true',
    share: '',
    sharePermission: 'view',
  };

  const handleSubmit = async (values, { setErrors }) => {
    setServerError();
    try {
      const medicalCase = {
        title: values.title,
        patientUuid: selectedPatient,
        description: values.description,
        specialty: values.specialty,
        active: values.active,
      };
      await createCase({ variables: { medicalCase } });
    } catch (e) {
      const formikErrors = parseGraphqlErrors(e.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(e);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const showServerError = () => {
    const message = serverError.message.includes('You are not allowed to perform this action')
      ? t('not.allowed.to.perform.action')
      : t('server.error');
    return <AlertUI title="Error" severity="error">{message}</AlertUI>;
  };

  const handleSelectedPatient = (e, uuid) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedPatient(uuid);
  };

  if (loading || called) return <Loading />;

  const propsSearch = {
    values: {
      search,
    },
    errors: {},
    handleChange: (e) => setSearch(e.target.value),
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >

      {step === 0
        && (
          <>
            <div className={classes.wrapperTitle}>
              <DialogTitle className={classes.closeButton}>
                {`${t('new.case')} - ${t('part')} ${step + 1} ${t('of')} 2`}
              </DialogTitle>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.wrapperSearch}>
              <h4>{t('first.step.select.or.create.a.patient')}</h4>
              <TextFieldSearch className={classes.search} label={t('search')} name="search" props={propsSearch} />
            </div>

            <DialogContent className={classes.content}>
              {
                filteredPatients.map((edge, index) => (
                  <div className="patient-card-wrapper" key={index.toString()} onClick={(evt) => handleSelectedPatient(evt, edge.node.uuid)}>
                    <PatientCardDialog patient={edge.node} selected={edge.node.uuid === selectedPatient} noCollapse noOpen />
                  </div>
                ))
              }
            </DialogContent>

            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => navigate('/patients/new')}
              >
                {t('create.a.new.patient')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                disabled={!selectedPatient}
                onClick={() => setStep(1)}
              >
                {t('select.patient')}
              </Button>
            </DialogActions>
          </>
        )}

      {step === 1
        && (
          <>
            <div className={classes.wrapperTitle}>
              <DialogTitle className={classes.closeButton}>
                {`${t('new.case')} - ${t('part')} ${step + 1} ${t('of')} 2`}
              </DialogTitle>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <DialogContent>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form autoComplete="off" id="addForm" onSubmit={props.handleSubmit}>
                    {!!serverError && showServerError()}
                    <h4>{t('second.step.create.your.case')}</h4>
                    <Margin mx-0>
                      <Row>
                        <Column>
                          <TextFieldUI label={t('specialty')} name="title" props={props} />
                        </Column>
                      </Row>
                    </Margin>
                    <Margin mx-0>
                      <Row>
                        <Column>
                          <TextFieldSelectUI
                            name="specialty"
                            label={t('specialty')}
                            props={props}
                            options={medicalSpecialtyList}
                          />
                        </Column>
                      </Row>
                    </Margin>
                    <Margin mx-0>
                      <Row>
                        <Column>
                          <Label htmlFor="description">{t('description')}</Label>
                          <QuillEditor
                            inForm
                            name="description"
                            formProps={props}
                            onChange={(html, text, form) => {
                              form.setFieldValue('description', text.trim().length ? html : '');
                            }}
                          />
                          <ErrorMessage name="description" component={Error} />
                        </Column>
                      </Row>
                    </Margin>
                  </Form>
                )}
              </Formik>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={() => setStep(0)}
              >
                {t('back')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                form="addForm"
                type="submit"
              >
                {t('create')}
              </Button>
            </DialogActions>
          </>
        )}
    </Dialog>
  );
};
