import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  helperTextRoot: {
    color: theme.palette.error.main,
  },
  // root: {
  //   '& .MuiInputBase-input': {
  //     fontFamily: 'Muli',
  //     color: '#606060',
  //   },
  // },
}));

export const TextFieldSearch = ({ type, className, name, label, props, disabled = false }) => {
  const { values, handleChange, errors } = props;
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      type={type}
      className={className}
      label={label}
      name={name}
      id={name}
      value={values[name]}
      onChange={handleChange}
      helperText={errors[name] && errors[name]}
      disabled={disabled}
      FormHelperTextProps={{
        classes: {
          root: classes && classes.helperTextRoot,
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
};
