import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';

import { Container, Menu, MenuItem } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import { useSelector } from 'react-redux';
import { isEmpty, sortGraphQueryList } from '../../utils/ObjectUtils';
import { PageListContent } from '../Common/styled/PageContent';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import Loading from '../Common/Loading';
import { SectionBar } from '../../componentsUI/SectionBar';

import { GET_PATIENT_MEDICAL_CASES } from '../../graphql/queries';
import TableCollapse from '../../componentsUI/TableCollapse';
import { CaseCollapse } from './CaseCollapse';
import { GetFormat } from '../../utils/functions';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const fieldNames = [
  { label: 'medical.case', id: 'title', field: 'TITLE', direction: 'ASC', width: 370 },
  { label: 'active', id: 'active', width: 15, format: 'ACTIVECASE2' },
  { label: 'case.created', id: 'createdAt', field: 'CREATED_AT', direction: 'DESC', width: 120, format: 'DATEFORMAT' },
];

export const CasesPatientView = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const sortData = {
    default: { field: 'CREATED_AT', direction: 'DESC' },
  };
  const [orderByField, setOrderByField] = useState(sortData.default.field);
  const [orderByDirection, setOrderByDirection] = useState(sortData.default.direction);

  const user = useSelector((state) => state.userInterface.user);
  const { loading, error, data, refetch } = useQuery(
    GET_PATIENT_MEDICAL_CASES, {
      variables: {
        uuid: user.patientUuid,
      },
    },
  );

  const reverseDirection = () => (orderByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === orderByField) {
        setOrderByDirection(reverseDirection());
      } else {
        setOrderByDirection(value.direction);
      }

      setOrderByField(value.field);
      refetch();
      setAnchorEl();
      setSelected([]);
    }
  };

  const medicalCases = data && data.patient && data.patient.medicalCases
    && sortGraphQueryList(data.patient.medicalCases, fieldNames, orderByField, orderByDirection);

  const handleGoto = (evt, index) => {
    evt.stopPropagation();
    const indexCase = medicalCases[index];

    navigate(`/case/${indexCase.uuid}`);
  };

  const getTitle = (item) => (
    `${item.title}`
  );

  const getSubTitle = (item) => (
    `${GetFormat(item.createdAt, 'DATEFORMAT')} - ${GetFormat(item.active, 'ACTIVECASE')}`
  );

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const buttons = [
    { name: 'sort.by', icon: SortIcon, handleClick: handleOrder, disabled: false },
  ];

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDownIcon className={className} /> : <ArrowDropUpIcon className={className} />
  );

  const errorMessage = networkErrorParse(error);

  if (errorMessage) {
    return (
      <AlertWrapperUI>
        <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
      </AlertWrapperUI>
    );
  }

  return (
    <ContainerUI>
      <Navbar>

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item, index) => (
            <MenuItem
              key={index.toString()}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === orderByField) && <OrderIcon direction={orderByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="cases" items={buttons} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <PageListContent>
          { loading && isEmpty(data) && <Loading /> }

          <CardWrapperUI>
            { medicalCases
              ? (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={medicalCases}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  GetCollapse={CaseCollapse}
                  selected={selected}
                  setSelected={setSelected}
                  handleGoto={handleGoto}
                  order={{ field: orderByField, direction: orderByDirection }}
                  handleOrder={handleOrderBy}
                />
              ) : (t('no.cases'))}
          </CardWrapperUI>
        </PageListContent>
      </Container>
    </ContainerUI>
  );
};
