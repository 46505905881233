import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '.9375em',
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 6,
    '& > div': {
      '&:first.child': {
        paddingTop: '1.25rem',
      },
      '&:last.child': {
        paddingBottom: '0',
      },
    },
  },
}));

export const CollapsedWrapper = ({ children }) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      {children}
    </Box>
  );
};

export default CollapsedWrapper;
