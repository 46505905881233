import React from 'react';

import Layout from '../../components/MenuLayout';
import { CasesView } from '../../components/Case/CasesView';
import { CasesPatientView } from '../../components/Case/CasesPatientView';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const { patientApp } = useApplicationInterface();

export default () => (
  <Layout>
    {patientApp ? <CasesPatientView /> : <CasesView />}
  </Layout>
);
