import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';

import { QuillWrapper } from './styled/QuillWrapper';
import QuillToolbar from './QuillToolbar';

export default ({ inForm, formProps, onChange, name = 'description' }) => {
  const { t } = useTranslation();

  return (
    <QuillWrapper inForm={inForm}>
      <ReactQuill
        bounds=".quill"
        placeholder={t('comment.placeholder')}
        modules={{
          toolbar: { container: '#toolbar' },
          clipboard: { matchVisual: false },
        }}
        value={formProps ? formProps.values[name] : ''}
        onChange={(content, delta, source, editor) => onChange(content, editor.getText(), formProps)}
      />
      <QuillToolbar />
    </QuillWrapper>
  );
};
