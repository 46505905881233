import React, { useState } from 'react';

import { navigate } from 'gatsby';
import { Collapse } from 'react-collapse';
import { isMobile, isTablet } from 'react-device-detect';

import { Card, CardDialog } from '../styled/Card';
import PatientCardDetails from './PatientCardDetails';
import { PatientCaseCard, NoCases } from '../CaseCard/CaseCard';
import CollapsedWrapper from '../styled/CollapsedWrapper';

export default ({ patient, selected, noCollapse, noOpen }) => {
  const isHandheld = isMobile || isTablet;
  const [showHiddenContent, setShowHiddenContent] = useState(false);

  return (
    <Card isUncollapsed={showHiddenContent} onClick={() => (isHandheld ? navigate(`/patient/${patient.uuid}`) : setShowHiddenContent(!showHiddenContent))} cursor="pointer" selected={selected}>
      <PatientCardDetails patient={patient} noOpen={noOpen} />
      {
        !isHandheld && !noCollapse
        && (
          <Collapse isOpened={showHiddenContent}>
            <CollapsedWrapper>
              {
                !!patient.medicalCases && patient.medicalCases.length
                  ? patient.medicalCases.map((medicalCase) => <PatientCaseCard medicalCase={medicalCase} key={medicalCase.uuid} />)
                  : <NoCases />
              }
            </CollapsedWrapper>
          </Collapse>
        )
      }
    </Card>
  );
};

export const PatientCardDialog = ({ patient, selected, noOpen }) => (
  <CardDialog cursor="pointer" selected={selected}>
    <PatientCardDetails patient={patient} noOpen={noOpen} />
  </CardDialog>
);
