import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import { Collapse } from 'react-collapse';
import { isMobile, isTablet } from 'react-device-detect';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

import { Card, useCardStyles } from '../styled/Card';
import { CollapsedWrapper } from '../styled/CollapsedWrapper';
import { StudyCard, NoStudies } from '../StudyCard/StudyCard';
import CaseCardDetails from './CaseCardDetails';
import { NoCasesWrapper } from './styled/NoCasesWrapper';
import { BasicFileCardInline } from '../BasicFileCard/BasicFileCard';
import { Column, Row } from '../styled/Groups';
import { getFormattedDate } from '../../../utils/dateTimeUtils';

export const CaseCard = ({ medicalCase }) => {
  const { t } = useTranslation();
  const isHandheld = isMobile || isTablet;
  const [showHiddenContent, setShowHiddenContent] = useState(false);

  return (
    <Card isUncollapsed={showHiddenContent} onClick={() => (isHandheld ? navigate(`/case/${medicalCase.uuid}`) : setShowHiddenContent(!showHiddenContent))} cursor="pointer">
      <CaseCardDetails medicalCase={medicalCase} />
      {
        !isHandheld
        && (
          <Collapse isOpened={showHiddenContent}>
            <CollapsedWrapper>
              {
                !!medicalCase.studies && !!medicalCase.studies.length
                  ? medicalCase.studies.map((study) => <StudyCard medicalCaseUuid={medicalCase.uuid} study={study} key={study.uuid} />)
                  : <NoStudies />
              }
              {
                !!medicalCase.medicalCaseFiles && medicalCase.medicalCaseFiles.length
                  ? medicalCase.medicalCaseFiles.map((file) => (<BasicFileCardInline medicalCase={medicalCase} file={file} key={file.uuid} />))
                  : <span>{t('no.files')}</span>
              }
            </CollapsedWrapper>
          </Collapse>
        )
      }
    </Card>
  );
};

export const PatientCaseCard = ({ medicalCase }) => {
  const classes = useCardStyles();
  return (
    <div className={classes.container}>
      <Link to={`/case/${medicalCase.uuid}`} key={medicalCase.uuid}>
        <div className={classes.wrapper}>
          <div>
            <FolderOpenIcon />
          </div>
          <Row>
            <Column>
              <p className={classes.label}>{medicalCase.title}</p>
              <p className={classes.date}>{getFormattedDate({ date: medicalCase.createdAt })}</p>
            </Column>
          </Row>
        </div>
      </Link>
    </div>
  );
};

export const NoCases = () => {
  const { t } = useTranslation();

  return (
    <NoCasesWrapper>
      <span>{t('patient.no.cases')}</span>
    </NoCasesWrapper>
  );
};
