import React from 'react';

import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { isMobile, isTablet } from 'react-device-detect';

import { PatientCardVisibleContent } from './styled/PatientCardVisibleContent';
import { GreyLabel } from '../styled/GreyLabel';
import { Button } from '../styled/Button';
import { FromNow } from '../FormattedDate';

export default ({ patient, noOpen }) => {
  const { t } = useTranslation();
  const isHandheld = isMobile || isTablet;

  return (
    <PatientCardVisibleContent>
      <div>
        {patient.name}
        {' '}
        {patient.surname}
        <br />
        <GreyLabel>
          {patient.customId}
        </GreyLabel>
      </div>
      <div>
        <GreyLabel>
          {t('last.modified')}
          {' '}
          <br />
        </GreyLabel>
        <FromNow date={patient.createdAt} />
      </div>
      {
        !isHandheld && !noOpen
        && (
        <div>
          <Link to={`/patient/${patient.uuid}`}><Button>{t('open')}</Button></Link>
        </div>
        )
      }
    </PatientCardVisibleContent>
  );
};
